import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Rows = makeShortcode("Rows");
const PlainColumn = makeShortcode("PlainColumn");
const Seperator = makeShortcode("Seperator");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Rows mdxType="Rows">
  <PlainColumn mdxType="PlainColumn">
        <h2>{`Entwicklungsumgebungen und Werkzeuge`}</h2>
        <p>{`Eclipse `}<Seperator mdxType="Seperator" />{` JDeveloper `}<Seperator mdxType="Seperator" />{` Microsoft Visual C++ `}<Seperator mdxType="Seperator" />{` Microsoft .NET `}<Seperator mdxType="Seperator" />{` Microsoft Access `}<Seperator mdxType="Seperator" />{` Oracle Designer `}<Seperator mdxType="Seperator" />{` Oracle Developer `}<Seperator mdxType="Seperator" />{` Oracle Enterprise Manager `}<Seperator mdxType="Seperator" />{` Oracle SQL Developer `}<Seperator mdxType="Seperator" />{` Crystal Reports `}<Seperator mdxType="Seperator" />{` Toad `}<Seperator mdxType="Seperator" />{` ANT `}<Seperator mdxType="Seperator" />{` Maven `}<Seperator mdxType="Seperator" />{` cc `}<Seperator mdxType="Seperator" />{` gcc `}<Seperator mdxType="Seperator" />{` make `}<Seperator mdxType="Seperator" />{` redmine `}<Seperator mdxType="Seperator" />{` JIRA `}<Seperator mdxType="Seperator" />{` subversion `}<Seperator mdxType="Seperator" />{` git`}<Seperator mdxType="Seperator" />{` testlink`}</p>
        <h2>{`Betriebssysteme`}</h2>
        <p>{`Linux `}<Seperator mdxType="Seperator" />{` Unix `}<Seperator mdxType="Seperator" />{` (Solaris `}<Seperator mdxType="Seperator" />{` HPUX `}<Seperator mdxType="Seperator" />{` AIX) `}<Seperator mdxType="Seperator" />{` Microsoft Windows `}<Seperator mdxType="Seperator" />{` MacOS `}<Seperator mdxType="Seperator" />{` iOS `}<Seperator mdxType="Seperator" />{` Android`}</p>
        <h2>{`Design und Modellierung`}</h2>
        <p>{`Oracle Designer `}<Seperator mdxType="Seperator" />{` Sybase Power Designer `}<Seperator mdxType="Seperator" />{` ER-Modellierung `}<Seperator mdxType="Seperator" />{` XML Schema Design `}<Seperator mdxType="Seperator" />{` UML`}</p>
        <h2>{`Sprachen und Frameworks`}</h2>
        <p>{`C `}<Seperator mdxType="Seperator" />{` C++ `}<Seperator mdxType="Seperator" />{` C# `}<Seperator mdxType="Seperator" />{` Ruby on Rails `}<Seperator mdxType="Seperator" />{` React Native `}<Seperator mdxType="Seperator" />{` Appcelerator Titanium SDK `}<Seperator mdxType="Seperator" />{` JavaScript `}<Seperator mdxType="Seperator" />{` HTML5 `}<Seperator mdxType="Seperator" />{` PHP `}<Seperator mdxType="Seperator" />{` Java, Groovy `}<Seperator mdxType="Seperator" />{` Java EE `}<Seperator mdxType="Seperator" />{` SQL `}<Seperator mdxType="Seperator" />{` PL/SQL, T-SQL `}<Seperator mdxType="Seperator" />{` UNIX-Shell `}<Seperator mdxType="Seperator" />{` VB `}<Seperator mdxType="Seperator" />{` VBA `}<Seperator mdxType="Seperator" />{` Perl`}</p>
  </PlainColumn>
  <PlainColumn mdxType="PlainColumn">
        <h2>{`Datenbanksysteme`}</h2>
        <p>{`Oracle `}<Seperator mdxType="Seperator" />{` PostgreSQL `}<Seperator mdxType="Seperator" />{` Cassandra `}<Seperator mdxType="Seperator" />{` MS SQL-Server `}<Seperator mdxType="Seperator" />{` Informix `}<Seperator mdxType="Seperator" />{` MS Access `}<Seperator mdxType="Seperator" />{` DB2 `}<Seperator mdxType="Seperator" />{` MySQL `}<Seperator mdxType="Seperator" />{` Sybase `}<Seperator mdxType="Seperator" />{` ObjectStore `}<Seperator mdxType="Seperator" />{` Versant`}</p>
        <h2>{`Datenbanktechnologien`}</h2>
        <p>{`Database Vault `}<Seperator mdxType="Seperator" />{` Data Warehouse `}<Seperator mdxType="Seperator" />{` Datenbanken Migration `}<Seperator mdxType="Seperator" />{` Dokumentenrecherche `}<Seperator mdxType="Seperator" />{` Volltextsuche `}<Seperator mdxType="Seperator" />{` Informatica Powermart/-center `}<Seperator mdxType="Seperator" />{` Pentaho BI Suite `}<Seperator mdxType="Seperator" />{` JDBC `}<Seperator mdxType="Seperator" />{` ODBC `}<Seperator mdxType="Seperator" />{` Oracle OCI/OCCI `}<Seperator mdxType="Seperator" />{` Oracle Pro`}{`*`}{`C `}<Seperator mdxType="Seperator" />{` Oracle Replikation `}<Seperator mdxType="Seperator" />{` Oracle Advanced Queueing `}<Seperator mdxType="Seperator" />{` Oracle Java Stored Procedures `}<Seperator mdxType="Seperator" />{` Oracle External Procedure Calls `}<Seperator mdxType="Seperator" />{` Oracle Forms `}<Seperator mdxType="Seperator" />{` Oracle Reports `}<Seperator mdxType="Seperator" />{` Oracle APEX`}</p>
        <h2>{`Application Server und Middleware`}</h2>
        <p>{`Oracle Application Server `}<Seperator mdxType="Seperator" />{` Apache Webserver `}<Seperator mdxType="Seperator" />{` nginx `}<Seperator mdxType="Seperator" />{` Oracle WebLogic Server `}<Seperator mdxType="Seperator" />{` JBoss `}<Seperator mdxType="Seperator" />{` Tomcat `}<Seperator mdxType="Seperator" />{` DevOps – Docker`}</p>
  </PlainColumn>
    </Rows>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      